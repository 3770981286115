<template>
  <div>
    <div
      class="list-wrap"
      v-if="list.length > 0"
      :style="{ height: height, overflowY: 'scroll' }"
    >
      <slot />
      <div style="text-align: center" v-if="showLoadSpin === 0">
        <span />
      </div>
      <div style="text-align: center" v-if="showLoadSpin === 1">
        <van-loading size="22px" color="#1989fa" type="spinner" />
      </div>
      <div style="text-align: center" v-if="showLoadSpin === 2">
        <div style="text-align: center; font-weight: bold">{{ $t('public.noMore') }}</div>
      </div>
    </div>
    <div v-else style="padding: 1rem 0; text-align: center">
      <h3>{{ $t('public.noData') }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "FlatList",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    height: {
      type: String,
      default: "300px",
    },
    showLoadSpin: {
      type: Number,
      default: 0,
    },
  },
  watch: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.list-wrap {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}
</style>
